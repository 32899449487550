<template>
  <div>
    <vs-row>
      <vs-col>
        <div class="campaign-controls">
          <div>
            <vs-button class="mb-base" color="primary" type="filled" icon="add" :class="!HAS_ACCESS ? 'disabled-button' : ''" :disabled="!HAS_ACCESS" @click.prevent="createCampaign">
              {{ $t('vue.addQRConnect') }}
            </vs-button>
          </div>
          <div style="width: 200px;">
            <multiselect v-if="IS_ADMIN" ref="country" v-model="selectedUser" :options="usersList" :multiple="false" :close-on-select="true" :clear-on-select="false" :placeholder="''" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" label="name" track-by="name" :name="$t('inputs.name')" open-direction="bottom" v-validate="'required'" :data-vv-as="$t('inputs.name')" :preserve-search="true" :preselect-first="false">
            </multiselect>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <div class="my-campaigns">
      <div class="my-campaigns-table-wrapper">
        <vs-table ref="table" pagination :max-items="itemsPerPage" :data="mycampaigns" :noDataText="$t('views.home.noDataAvailable')" class="campaigns-table" :class="!HAS_ACCESS && !isMobileApp ? 'blur-background' : ''">
          <template slot="thead">
            <vs-th sort-key="campaignname">{{ $t('vue.QRConnectName') }}</vs-th>
            <!-- <vs-th sort-key="landingpage">{{ $t('vue.landingPage') }}</vs-th> -->
            <vs-th sort-key="agent">{{ $t('vue.agent') }}</vs-th>
            <vs-th>{{ $t('vue.action') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="tr.id" v-for="(tr, index) in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.name }}</p>
                </vs-td>

                <!-- <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.landingPage }}</p>
                </vs-td> -->

                <vs-td>
                  <p class="product-name font-medium truncate">
                    <span v-for="recipient in tr.recipients" :key="recipient.id">
                      {{ recipient.name }}{{ tr.recipients.slice(-1)[0].id === recipient.id ? '' : ',' }}
                    </span>
                  </p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <vs-prompt :title="$t('modals.deleteQrConnect')" @cancel="onCloseModal" @accept="() => deleteCampaign(trId)" @close="onCloseModal" color="danger" :cancel-text="$t('vue.cancel')" :accept-text="$t('vue.delete')" :active.sync="removeCampaign">
                    <div class="con-exemple-prompt" v-if="campaignName">
                      <p>
                        <strong>{{ campaignName }}</strong>
                      </p>
                      {{ $t('modals.deleteQrConnectQuestion') }}
                    </div>
                  </vs-prompt>
                  <vs-popup :title="$t('vue.QRCode')" :active.sync="showQRCode" class="cell-renderer-qr-code">
                    <vue-qrcode ref="qrCodeImage" id="campaign-qr-code" :value="getMeetingLink(trId)" :maskPattern="2" />
                    <div class="flex justify-between">
                      <vs-button class="mr-2" type="border" size="small" icon-pack="feather" icon="icon-clipboard" @click="copyQRImage(qrPopupIndex)">
                        {{ $t('vue.copy') }}
                      </vs-button>
                      <vs-button type="border" size="small" icon-pack="feather" icon="icon-download" color="#F0A941" @click="downloadQRImage(qrPopupIndex, qrImageName)">
                        {{ $t('vue.download') }}
                      </vs-button>
                    </div>
                  </vs-popup>
                  <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                    <vx-tooltip :text="$t('routes.settings')" position="top">
                      <vs-button type="border" size="medium" color="#3B86F7" icon-pack="feather" icon="icon-settings" @click.stop="editCampaign(tr.id)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('info.copyConnectLink')" position="top" class="ml-3">
                      <vs-button color="#3EA54E" type="border" size="medium" icon-pack="feather" icon="icon-link" v-clipboard:copy="getMeetingLink(tr.id)" v-clipboard:success="onCopy" v-clipboard:error="onError"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('info.duplicateQRConnect')" position="top" class="ml-3">
                      <vs-button color="#ff9f43" type="border" size="medium" icon-pack="feather" icon="icon-copy" @click="createDuplicate(tr.id)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('info.showQRCode')" position="top" class="ml-3">
                      <vs-button type="border" size="medium" color="#12598D" icon-pack="material-icons" icon="qr_code_2" @click="
                          showQRCode = true
                          qrPopupIndex = index
                          qrImageName = tr.name
                          trId = tr.id
                        "></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('vue.deleteQRConnect')" position="top" class="ml-3">
                      <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="
                          removeCampaign = true
                          campaignName = tr.name
                          trId = tr.id
                        "></vs-button>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>

      <div v-if="mycampaigns.length > 0" class="vx-row my-campaigns__cards">
        <div v-for="(mycampaign, index) in mycampaigns" :key="mycampaign.id" class="vx-col w-full my-campaigns__card">
          <vx-card class="overflow-hidden my-campaigns__card-container" style="cursor: pointer">
            <div class="my-campaigns__card__heading">
              <div class="w-full flex justify-between">
                <div>
                  <h2 class="pr-3 font-bold my-campaigns__card__title">{{ mycampaign.name }}</h2>
                </div>
              </div>
            </div>

            <div class="mt-auto text-center my-campaigns__card__actions">
              <div class="flex">
                <vx-tooltip :text="$t('info.copyConnectLink')" position="top">
                  <vs-button color="#3EA54E" type="border" size="medium" icon-pack="feather" icon="icon-copy" v-clipboard:copy="getMeetingLink(mycampaign.id)" v-clipboard:success="onCopy" v-clipboard:error="onError"></vs-button>
                </vx-tooltip>

                <vx-tooltip :text="$t('info.showQRCode')" position="top" class="ml-4">
                  <vs-button type="border" size="medium" color="#12598D" icon-pack="material-icons" icon="qr_code_2" @click="
                      showQRCode = true
                      qrPopupIndex = index
                      qrImageName = mycampaign.name
                      trId = mycampaign.id
                    "></vs-button>
                </vx-tooltip>

                <vx-tooltip :text="$t('vue.deleteQRConnect')" position="top" class="ml-4">
                  <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="
                      removeCampaign = true
                      campaignName = mycampaign.name
                      trId = mycampaign.id
                    "></vs-button>
                </vx-tooltip>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <campaign-message v-if="!HAS_ACCESS && !isMobileApp" />
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import { mapGetters } from 'vuex'

import VueQrcode from 'vue-qrcode'
import CampaignMessage from '@/components/upgrade-messages/CampaignMessage.vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import { Device } from '@capacitor/device'

export default {
  components: {
    VueQrcode,
    CampaignMessage,
    Multiselect
  },
  data() {
    return {
      isMobileApp: false,
      coBrowsing: null,
      removeCampaign: false,
      campaignName: '',
      showQRCode: false,
      qrPopupIndex: 0,
      qrImageName: '',
      trId: null,
      itemsPerPage: 5,
      selectedUser: null
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      campaigns: 'campaigns',
      users: 'users',
      company: 'company'
    }),
    usersList() {
      return this.users.map((user) => {
        let name = user.displayName
        if (!name || name.trim().length === 0) {
          name = `${user.firstname} ${user.lastname}`
        }
        return {
          id: user.id,
          name
        }
      })
    },

    defaultCampaignnName() {
      return this.translate('vue.newCampaign')
    },
    IS_ADMIN() {
      if (this.activeUserInfo && this.activeUserInfo.role === 'admin') {
        return true
      }

      if (
        this.activeUserInfo &&
        typeof this.activeUserInfo.isAgent !== 'undefined' &&
        typeof this.activeUserInfo.isSupervisor !== 'undefined' &&
        !this.activeUserInfo.isAgent &&
        !this.activeUserInfo.isSupervisor
      ) {
        return true
      }

      return false
    },
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('qr-code') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    coBrowsingUrl() {
      const user = this.activeUserInfo
      if (user && user.companyInfo) {
        return user.companyInfo.url
      } else {
        return null
      }
    },
    mycampaigns() {
      if (this.selectedUser) {
        return this.campaigns.filter((x) => x.createdBy === this.selectedUser.id)
      }

      return this.campaigns.filter((x) => x.createdBy === this.activeUserInfo.uid)
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    validateForm() {
      return this.errors.any()
    }
  },

  created() {
    this.checkIsMobileApp()
  },

  mounted() {
    if (this.usersList && this.activeUserInfo && this.activeUserInfo.uid) {
      const currentUser = this.usersList.find((x) => x.id === this.activeUserInfo.uid)
      if (currentUser) {
        this.selectedUser = { ...currentUser }
      }
    }
  },

  methods: {
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    getNewCampaignName() {
      if (!this.campaigns || this.campaigns.length === 0) {
        return this.defaultCampaignnName
      }
      if (!this.campaigns.find((x) => x.name === this.defaultCampaignnName)) {
        return this.defaultCampaignnName
      }
      for (let i = 1; ; i++) {
        const name = `${this.defaultCampaignnName} ${i}`
        if (!this.campaigns.find((x) => x.name === name)) {
          return name
        }
      }
    },
    onCloseModal() {
      this.removeCampaign = false
    },
    getCorrectLocationUrl(url) {
      return url && url.includes('capacitor') ? this.$config.hostUrl : location.origin
    },
    getMeetingLink(id) {
      return `${this.getCorrectLocationUrl(location.origin)}/pages/qr-connect?cid=${id}`
    },
    async createDuplicate(id) {
      await this.$vs.loading()
      const campaignRef = await this.$db.collection('campaigns').doc(id).get()
      const campaign = campaignRef.data()
      if (campaign) {
        campaign.created = dayjs().utc().toDate()
        campaign.createdBy = this.activeUserInfo.uid
        campaign.copied_from = id
        const copyNumber = this.campaigns.filter((x) => x.copied_from === id).length + 1
        campaign.name = `${campaign.name} ${this.translate('vue.copy2')} - ${copyNumber}`
        await this.$db.collection('campaigns').add(campaign)
      }
      await this.$vs.loading.close()
    },
    editCampaign(id) {
      this.$router.push(`/qr-connect/${id}`)
    },
    generateQRBlob(index) {
      const base64Data = this.$refs.qrCodeImage[index].$el.currentSrc.substring(this.$refs.qrCodeImage[index].$el.currentSrc.indexOf(',') + 1)
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'image/png' })
      return blob
    },
    copyQRImage(index) {
      const blob = this.generateQRBlob(index)
      const item = new window.ClipboardItem({ 'image/png': blob })

      navigator.clipboard.write([item])

      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.QRCodeImageCopiedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    downloadQRImage(index, qrImageName) {
      const blob = this.generateQRBlob(index)
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${qrImageName}.png`
      link.click()
      URL.revokeObjectURL(link.href)

      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.QRCodeImageDownloadedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onCopy() {
      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.linkCopiedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: this.translate('vue.error'),
        text: this.translate('vue.errorCopyingText'),
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    },
    async deleteCampaign(id) {
      try {
        this.removeCampaign = false

        await this.$db.collection('campaigns').doc(id).delete()
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    getDefaultCampgaignDialogValues() {
      const dialog = {
        name: this.getNewCampaignName(),
        company: this.activeUserInfo.company,
        landingPage: 'https://www.mycompanyname/landingPage',
        recipients: [],
        title: this.$i18n.t('vue.dialogTitleDefault', 'en'),
        moreInformationButtonText: this.$i18n.t('vue.moreInformation', 'en'),
        text: this.$i18n.t('vue.popupTextDefault', 'en'),
        cancelButtonText: this.$i18n.t('vue.cancelButtonTextDefault', 'en'),
        confirmButtonText: this.$i18n.t('vue.confirmButtonTextDefault', 'en'),
        confirmButtonColor: '#238000',
        confirmButtonTextColor: '#ffffff',
        visibleTimeout: 4,
        contactFormTimeout: 60,
        created: new Date(),
        showVideo: false,
        type: 'button-card',
        visibility: 'always',
        locale: this.$i18n.locale,
        createdBy: this.activeUserInfo.uid,
        dialogUpdated: new Date(),
        isShowedUpContactFormWhenNoAgentOnline: false
      }

      /* New Dialog Fields */
      dialog.isMoreInformationEnabled = false

      dialog.textStep2 = this.$i18n.t('vue.textStep2DefaultText', 'en')
      dialog.step2ButtonColor = '#3B86F7'
      dialog.step2TextColor = '#ffffff'
      dialog.waitingRoomMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')

      dialog.waitingRoomContentChatMessage = {
        backgroundColor: '#C4C4C4',
        fontColor: '#12598d'
      }

      dialog.sessionEndScreen = {
        titleClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en'),
        copyClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF'
      }

      dialog.contactForm = {
        message: this.$i18n.t('vue.contactFormText', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF',
        isBookingEnabled: false,
        selectedAppointment: null,
        fields: {
          name: {
            enabled: true,
            required: true
          },
          email: {
            enabled: true,
            required: true
          },
          phone: {
            enabled: true,
            required: true
          },
          message: {
            enabled: true,
            required: true
          },
          date: {
            enabled: false,
            required: false
          }
        },
        sendMessageTitle: this.$i18n.t('vue.sendMessageDefaultTitle', 'en'),
        sendMessageCopy: this.$i18n.t('vue.sendMessageDefaultCopy', 'en'),
        bookingSlotTitle: this.$i18n.t('vue.bookingSlotDefaultTitle', 'en'),
        bookingSlotCopy: this.$i18n.t('vue.bookingSlotDefaultCopy', 'en'),
        isPrivacyInformationEnabled: false,
        privacyInformationHtml: this.$i18n.t('vue.privacyInformationText', 'en')
      }
      /* New Dialog Fields */

      return dialog
    },
    async createCampaign() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        await this.$vs.loading()
        const campaign = this.getDefaultCampgaignDialogValues()
        try {
          const campaignRef = await this.$db.collection('campaigns').add(campaign)
          campaign.id = campaignRef.id
          this.editCampaign(campaign.id)
          await this.$vs.loading.close()
        } catch (error) {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.campaign-controls {
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    display: none;
  }

  .multiselect {
    z-index: 999;

    &__content-wrapper {
      position: absolute !important;
    }
  }
}

.my-campaigns {
  &-table-wrapper {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__cards {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__card {
    margin-bottom: 15px;

    .vx-card__body {
      padding: 16px !important;
    }

    &__title {
      overflow: hidden;
      font-size: 24px;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }

    &__actions {
      margin-top: 20px !important;

      .vs-button {
        width: 40px !important;
        height: 40px !important;

        .vs-icon {
          font-size: 20px !important;
        }
      }
    }
  }
}

.campaign-controls > div {
  margin-right: 10px;
}

.ag-root {
  font-size: 0.85rem !important;
}

.cell-renderer-qr-code .vs-popup {
  width: 213px !important;
  height: auto !important;
}

.blur-background {
  filter: blur(2px);
}
.default-dialog {
  background-color: rgba(0, 103, 240, 0.1) !important;
}

.dialog-container {
  position: relative;
}
.disabled-button {
  background-color: #c4c4c4 !important;
}
.disabled-font {
  color: #c4c4c4 !important;
}

.middle {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.bottom-right {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  -ms-transform: translate(-100%, -100%);
  text-align: center;
  width: 50%;
}
.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.dialog-container:hover .dialog {
  opacity: 0.4;
}
.dialog-container:hover .middle {
  opacity: 1;
}
.dialog-container:hover .bottom-right {
  opacity: 1;
}
.multiselect__content-wrapper {
  position: relative !important;
}
.vs-con-table.campaigns-table {
  .vs-table--header {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    .vs-table--search {
      padding-top: 0;

      .vs-table--search-input {
        padding: 0.9rem 2.5rem;
        font-size: 1rem;

        & + i {
          left: 1rem;
        }

        &:focus + i {
          left: 1rem;
        }
      }
    }
  }

  .vs-table--pagination {
    padding: 0;
  }

  .vs-table {
    border-collapse: separate;
    border-spacing: 0 1.3rem;

    tr {
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

      td {
        padding: 10px;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          width: 350px;
        }

        &:nth-child(2) {
          width: 375px;
        }

        &:nth-child(3) {
          width: 375px;
        }

        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          width: 300px;
        }
      }

      td.td-check {
        padding: 20px !important;
      }
    }
  }

  .vs-table--thead {
    th {
      padding-top: 0;
      padding-bottom: 0;

      .vs-table-text {
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    th.td-check {
      padding: 0 15px !important;
    }

    tr {
      background: none;
      box-shadow: none;
    }
  }

  .vs-table--pagination {
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.my-campaigns {
  position: relative;
  .upgrade-message-campaign {
    align-items: center;
    justify-content: center;
  }
}

.dialog-container {
  position: relative;
}
.disabled-button {
  background-color: #c4c4c4 !important;
}
.disabled-font {
  color: #c4c4c4 !important;
}

.middle {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.bottom-right {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  -ms-transform: translate(-100%, -100%);
  text-align: center;
  width: 50%;
}
.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.dialog-container:hover .dialog {
  opacity: 0.4;
}
.dialog-container:hover .middle {
  opacity: 1;
}
.dialog-container:hover .bottom-right {
  opacity: 1;
}
</style>
