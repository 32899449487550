var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c("vs-col", [
            _c("div", { staticClass: "campaign-controls" }, [
              _c(
                "div",
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-base",
                      class: !_vm.HAS_ACCESS ? "disabled-button" : "",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "add",
                        disabled: !_vm.HAS_ACCESS,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.createCampaign.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.addQRConnect")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "200px" } },
                [
                  _vm.IS_ADMIN
                    ? _c("multiselect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        ref: "country",
                        attrs: {
                          options: _vm.usersList,
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": false,
                          placeholder: "",
                          selectLabel: "",
                          selectedLabel: "",
                          deselectLabel: "",
                          label: "name",
                          "track-by": "name",
                          name: _vm.$t("inputs.name"),
                          "open-direction": "bottom",
                          "data-vv-as": _vm.$t("inputs.name"),
                          "preserve-search": true,
                          "preselect-first": false,
                        },
                        model: {
                          value: _vm.selectedUser,
                          callback: function ($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "my-campaigns" },
        [
          _c(
            "div",
            { staticClass: "my-campaigns-table-wrapper" },
            [
              _c(
                "vs-table",
                {
                  ref: "table",
                  staticClass: "campaigns-table",
                  class:
                    !_vm.HAS_ACCESS && !_vm.isMobileApp
                      ? "blur-background"
                      : "",
                  attrs: {
                    pagination: "",
                    "max-items": _vm.itemsPerPage,
                    data: _vm.mycampaigns,
                    noDataText: _vm.$t("views.home.noDataAvailable"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _c(
                            "tbody",
                            _vm._l(data, function (tr, index) {
                              return _c(
                                "vs-tr",
                                { key: tr.id, attrs: { data: tr } },
                                [
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [_vm._v(_vm._s(tr.name))]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      _vm._l(
                                        tr.recipients,
                                        function (recipient) {
                                          return _c(
                                            "span",
                                            { key: recipient.id },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(recipient.name) +
                                                  _vm._s(
                                                    tr.recipients.slice(-1)[0]
                                                      .id === recipient.id
                                                      ? ""
                                                      : ","
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    { staticClass: "whitespace-no-wrap" },
                                    [
                                      _c(
                                        "vs-prompt",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "modals.deleteQrConnect"
                                            ),
                                            color: "danger",
                                            "cancel-text": _vm.$t("vue.cancel"),
                                            "accept-text": _vm.$t("vue.delete"),
                                            active: _vm.removeCampaign,
                                          },
                                          on: {
                                            cancel: _vm.onCloseModal,
                                            accept: function () {
                                              return _vm.deleteCampaign(
                                                _vm.trId
                                              )
                                            },
                                            close: _vm.onCloseModal,
                                            "update:active": function ($event) {
                                              _vm.removeCampaign = $event
                                            },
                                          },
                                        },
                                        [
                                          _vm.campaignName
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "con-exemple-prompt",
                                                },
                                                [
                                                  _c("p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(_vm.campaignName)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "modals.deleteQrConnectQuestion"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "vs-popup",
                                        {
                                          staticClass: "cell-renderer-qr-code",
                                          attrs: {
                                            title: _vm.$t("vue.QRCode"),
                                            active: _vm.showQRCode,
                                          },
                                          on: {
                                            "update:active": function ($event) {
                                              _vm.showQRCode = $event
                                            },
                                          },
                                        },
                                        [
                                          _c("vue-qrcode", {
                                            ref: "qrCodeImage",
                                            refInFor: true,
                                            attrs: {
                                              id: "campaign-qr-code",
                                              value: _vm.getMeetingLink(
                                                _vm.trId
                                              ),
                                              maskPattern: 2,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "vs-button",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    type: "border",
                                                    size: "small",
                                                    "icon-pack": "feather",
                                                    icon: "icon-clipboard",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyQRImage(
                                                        _vm.qrPopupIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("vue.copy")
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-button",
                                                {
                                                  attrs: {
                                                    type: "border",
                                                    size: "small",
                                                    "icon-pack": "feather",
                                                    icon: "icon-download",
                                                    color: "#F0A941",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadQRImage(
                                                        _vm.qrPopupIndex,
                                                        _vm.qrImageName
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("vue.download")
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            direction: _vm.$vs.rtl
                                              ? "rtl"
                                              : "ltr",
                                          },
                                        },
                                        [
                                          _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                text: _vm.$t("routes.settings"),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  color: "#3B86F7",
                                                  "icon-pack": "feather",
                                                  icon: "icon-settings",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.editCampaign(
                                                      tr.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                text: _vm.$t(
                                                  "info.copyConnectLink"
                                                ),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                directives: [
                                                  {
                                                    name: "clipboard",
                                                    rawName: "v-clipboard:copy",
                                                    value: _vm.getMeetingLink(
                                                      tr.id
                                                    ),
                                                    expression:
                                                      "getMeetingLink(tr.id)",
                                                    arg: "copy",
                                                  },
                                                  {
                                                    name: "clipboard",
                                                    rawName:
                                                      "v-clipboard:success",
                                                    value: _vm.onCopy,
                                                    expression: "onCopy",
                                                    arg: "success",
                                                  },
                                                  {
                                                    name: "clipboard",
                                                    rawName:
                                                      "v-clipboard:error",
                                                    value: _vm.onError,
                                                    expression: "onError",
                                                    arg: "error",
                                                  },
                                                ],
                                                attrs: {
                                                  color: "#3EA54E",
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-link",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                text: _vm.$t(
                                                  "info.duplicateQRConnect"
                                                ),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  color: "#ff9f43",
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-copy",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.createDuplicate(
                                                      tr.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                text: _vm.$t("info.showQRCode"),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  color: "#12598D",
                                                  "icon-pack": "material-icons",
                                                  icon: "qr_code_2",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showQRCode = true
                                                    _vm.qrPopupIndex = index
                                                    _vm.qrImageName = tr.name
                                                    _vm.trId = tr.id
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                text: _vm.$t(
                                                  "vue.deleteQRConnect"
                                                ),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  color: "danger",
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-trash",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.removeCampaign = true
                                                    _vm.campaignName = tr.name
                                                    _vm.trId = tr.id
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { attrs: { "sort-key": "campaignname" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.QRConnectName"))),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "agent" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.agent"))),
                      ]),
                      _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.mycampaigns.length > 0
            ? _c(
                "div",
                { staticClass: "vx-row my-campaigns__cards" },
                _vm._l(_vm.mycampaigns, function (mycampaign, index) {
                  return _c(
                    "div",
                    {
                      key: mycampaign.id,
                      staticClass: "vx-col w-full my-campaigns__card",
                    },
                    [
                      _c(
                        "vx-card",
                        {
                          staticClass:
                            "overflow-hidden my-campaigns__card-container",
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-campaigns__card__heading" },
                            [
                              _c(
                                "div",
                                { staticClass: "w-full flex justify-between" },
                                [
                                  _c("div", [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "pr-3 font-bold my-campaigns__card__title",
                                      },
                                      [_vm._v(_vm._s(mycampaign.name))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-auto text-center my-campaigns__card__actions",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("info.copyConnectLink"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value: _vm.getMeetingLink(
                                              mycampaign.id
                                            ),
                                            expression:
                                              "getMeetingLink(mycampaign.id)",
                                            arg: "copy",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.onCopy,
                                            expression: "onCopy",
                                            arg: "success",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:error",
                                            value: _vm.onError,
                                            expression: "onError",
                                            arg: "error",
                                          },
                                        ],
                                        attrs: {
                                          color: "#3EA54E",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-copy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vx-tooltip",
                                    {
                                      staticClass: "ml-4",
                                      attrs: {
                                        text: _vm.$t("info.showQRCode"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          type: "border",
                                          size: "medium",
                                          color: "#12598D",
                                          "icon-pack": "material-icons",
                                          icon: "qr_code_2",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showQRCode = true
                                            _vm.qrPopupIndex = index
                                            _vm.qrImageName = mycampaign.name
                                            _vm.trId = mycampaign.id
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vx-tooltip",
                                    {
                                      staticClass: "ml-4",
                                      attrs: {
                                        text: _vm.$t("vue.deleteQRConnect"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          color: "danger",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.removeCampaign = true
                                            _vm.campaignName = mycampaign.name
                                            _vm.trId = mycampaign.id
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.HAS_ACCESS && !_vm.isMobileApp
            ? _c("campaign-message")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }